<template>
  <div class="previous-works">
    <el-image fit="cover" v-if="workInfo.imageUrl" :src="workInfo.imageUrl"></el-image>
    <div class="content">
      <div class="title">
        <span>
          <!-- 2018年木玩创意设计营 ｜ 优秀作品 -->
          {{ workInfo.title }}
        </span>
      </div>
      <div class="sub-title">
        <!-- 山水之间—“中国传统故事”大冒险 -->
        <span v-html="workInfo.name"></span>
      </div>
    </div>
    <div class="desc">
      <div class="top">
        <div class="author"><span class="label">设计师</span>：{{ workInfo.author }}</div>
      </div>
      <div class="center">
        <div class="label">作品简介</div>
        <div class="detail">
          {{ workInfo.context }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from '@/api/apiService';
import API from '@/api';
import { LanguageEnum, Language } from '@/utils/constants';

export default {
  name: 'previous-works',
  data() {
    return {
      workInfo: {}
    };
  },
  components: {},
  watch: {
    '$store.state.isChinese'() {
      this.getNewsWorksDetail(this.$route.params.id);
    }
  },
  mounted() {
    this.getNewsWorksDetail(this.$route.params.id);
  },
  methods: {
    async getNewsWorksDetail(id) {
      const result = await ApiService.get(API.newsWorkDetail + id, {
        language: LanguageEnum[this.$store.state.isChinese ? Language.CN : Language.EN]
      });
      this.workInfo = result.data;
      // this.bannerList = result?.data?.desc || [];
    }
  }
};
</script>

<style scoped lang="less">
.previous-works {
  .content {
    .title {
      margin: 2.1vw 0 1.388vw;
      span {
        padding: 4px 1.2vw;
        height: 45px;
        color: #fff;
        background: #1a2043;
        border-radius: 6px;
      }
    }
    .sub-title {
      color: #1a2043;
    }
  }
  .desc {
    margin: 2.1vw 0 3.5vw;
    text-align: left;
    font-size: 1vw;
    font-weight: 400;
    color: #515151;
    line-height: 1.388vw;
    .label {
      font-weight: 700;
    }
    .top {
      margin-bottom: 2.1vw;
    }
  }
}
</style>
